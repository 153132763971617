import { PageType } from "src/typings/flexFramework/FlexDefinitions";

export const DEFAULT_BOOKING_WINDOW = 30;
export const OPTIMIZED_BOOKING_WINDOW = 14;
export const DEFAULT_OPTIMIZATION_WIDTH = "400px";
export const DESTINATION_LANDING_PAGE_TYPES = new Set<PageType>([
  "Travel-Guide-Hotels",
  "Travel-Guide-Filter-Hotels",
  "Hotel-Destinations",
  "Travel-Guide-Accommodation",
  "Travel-Guide-VacationRentals",
  "Vacation-Rental-Destinations",
  "Hotel-Filter-Destinations",
  "Alternative-Accommodations",
]);
